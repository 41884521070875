import './bit.js';
import Cookies from 'js-cookie';
import 'owl.carousel';

jQuery( document ).ready(function($) { 
    function login(e){
        $('header').addClass('login');
        $('footer').addClass('login');
        $('.logout').slideUp();
        $('.hidden').removeClass('hidden');
        
        $('body, html').animate({
            scrollTop: 0
        });
    }
    let isLoggedin = Cookies.get('login');
    if (isLoggedin){
        login();
    }

    var url = window.location.href;
    if(url.indexOf('state=thank-you') != -1){
        login();
    }
	$('#shows').bit({
		artist: 'niko moon',
		limit: 5,
		show_y: false,
		more_msg: 'Load More Dates',
		show_vip: 'true',
		vip_msg: 'Meet & Greet' 
	});

    $('#dob_picker').datepicker({
        changeMonth: true,
        changeYear: false,
        showButtonPanel: true,
        dateFormat: 'mm-dd'
    });

    $('.owl-carousel').owlCarousel({
        items: 1,
        nav:true,
        dots: false,
        navText:[
            '<i class="fas fa-arrow-left"></i><span class="sr-only">Previous</span>',
            '<i class="fas fa-arrow-right"></i><span class="sr-only">Next</span>'
        ]
    })

    $('.mg-form').on('submit', function(e) {
        e.preventDefault();
        const DATA = $(this).serialize();
    
        $.ajax({
            type: 'POST',
            url: $(this).attr('action'),
            dataType: 'json',
            data: DATA,
            xhrFields: {
                withCredentials: false,
            },
            success: function (data) {
                $('.inputs-wrap').html('<p class="newsletter-thanks">Thanks for signing up!</p>');
            },
            error: function (err) {
                console.log(err);
            }
        });
    });


    $('.signup-form').on('submit', function(e) {
        e.preventDefault();
        const DATA = $(this).serialize();
    
        $.ajax({
            type: 'POST',
            url: $(this).attr('action'),
            dataType: 'json',
            data: DATA,
            xhrFields: {
                withCredentials: false,
            },
            success: function (data) {
                Cookies.set('login', true, { expires:365 })
                login();
            },
            error: function (err) {
                console.log(err);
            }
        });
    });

    $('#login').on('click', function(e){
        e.preventDefault();
        login();
    });
});